<template>
    <div>
        <b-container class="p-2" style="background-color: rgba(150, 150, 150, 0.01)">
            <b-row no-gutters class="d-flex">
                <b-col xs="auto" sm="auto" md="auto" lg="auto" xl="auto" class="m-sm-auto">
                    <div class="text-center">
                        <img style="height: 4.25rem; width: 20rem" src="SmartRacks.svg" class="rounded mb-2">
                    </div>
                </b-col>
            </b-row>
            <b-row v-if="datasource.Approved !== null" no-gutters class="p-5 d-flex">
                <b-col xs="auto" sm="auto" md="auto" lg="auto" xl="auto" class="p-2 w-100 m-sm-auto">
                    <h1 class="text-center font-weight-bolder">This request has already been processed...</h1>
                </b-col>
            </b-row>
            <b-row v-else-if="!processed" no-gutters class="p-2 d-flex">
                <b-col xs="auto" sm="auto" md="auto" lg="auto" xl="auto" class="px-0 w-100">
                    <b-card bg-variant="light" :header="datasource && datasource.Customer.CustomerName" class="text-center d-flex">
                        <b-card-text class="text-left">
                            <DxForm
                                    id="form"
                                    :form-data="datasource"
                                    :col-count-by-screen="{ lg: 2, md: 2, sm: 2, xs: 2 }"
                                    :screen-by-width="getSizeQualifier"
                                    :col-count="2"
                                    label-location="left"
                                    class="p-2"
                                    width="100%"
                            >
                                <DxGroupItem
                                        :col-count="2"
                                        :col-span="2"
                                >
                                    <DxSimpleItem
                                            data-field="CreditProfile.lastCreditLimit"
                                            :label="{ text: 'Credit Limit' }"
                                            :editor-options="{ readOnly: true, format: '$ #,##0.00', inputAttr: { class: 'text-right' } }"
                                            :col-span="2"
                                    />
                                    <DxSimpleItem
                                            data-field="CreditProfile.availableCreditAmount"
                                            :label="{ text: 'Available Credit Amount'}"
                                            :editor-options="{ readOnly: true, format: '$ #,##0.00', inputAttr: { class: 'text-right' } }"
                                            :col-span="2"
                                    />
                                    <DxSimpleItem
                                            data-field="CreditProfile.totalARAmount"
                                            :label="{ text: 'Total A/R Amount'}"
                                            :editor-options="{ readOnly: true, format: '$ #,##0.00', inputAttr: { class: 'text-right' } }"
                                            :col-span="2"
                                    />
                                    <DxSimpleItem
                                            data-field="CreditProfile.totalPendingAmount"
                                            :label="{ text: 'Total Pending Amount'}"
                                            :editor-options="{ readOnly: true, format: '$ #,##0.00', inputAttr: { class: 'text-right' } }"
                                            :col-span="2"
                                    />

                                    <DxSimpleItem
                                            data-field="CreditProfile.totalAvailableLoads"
                                            :label="{ text: 'Total Available Loads'}"
                                            :editor-options="{ readOnly: true, inputAttr: { class: 'text-right' } }"
                                            :col-span="2"
                                    />

                                    <DxEmptyItem :col-span="2" />

                                    <DxSimpleItem
                                            data-field="RequestedLoadCount"
                                            :col-span="2"
                                    >
                                        <DxLabel text="Requested Loads" />
                                        <template #default>
                                            <DxNumberBox
                                                    :read-Only="true"
                                                    :input-attr="{ class: 'text-center', style: 'font-weight: bolder;' }"
                                                    :element-attr="{ style: 'border-color: rgba(0, 0, 0, 0.35) !important;' }"
                                                    :max="max"
                                                    :min="min"
                                                    :step="step"
                                                    :value="payload.ApprovedLoadCount"
                                                    mode="number"
                                                    @value-changed="onLoadsChanged"
                                            >
                                                <DxNumberBoxButton
                                                        :options="prevButton"
                                                        name="prevValue"
                                                        location="before"
                                                />
                                                <DxNumberBoxButton
                                                        :options="nextButton"
                                                        name="nextValue"
                                                        location="after"
                                                />
                                            </DxNumberBox>
                                        </template>
                                    </DxSimpleItem>

                                    <DxSimpleItem
                                            data-field="Comment"
                                            editor-type="dxTextArea"
                                            :editor-options="{ height: 100, onValueChanged: onCommentChanged}"
                                            :label="{ text: 'Comments', location: 'top' }"
                                            :col-span="2"
                                    />
                                </DxGroupItem>

                                <DxButtonItem :col-span="1">
                                    <DxButtonOptions
                                            text="Decline"
                                            type="danger"
                                            width="100%"
                                            :height="35"
                                            icon="clear"
                                            :on-click="DeclineRequest"
                                    />
                                </DxButtonItem>

                                <DxButtonItem :col-span="1">
                                    <DxButtonOptions
                                            text="Approve"
                                            type="success"
                                            icon="check"
                                            width="100%"
                                            :height="35"
                                            :input-attr="{ style: 'font-size: 12px;' }"
                                            :on-click="ApproveRequest"
                                    />
                                </DxButtonItem>
                            </DxForm>
                        </b-card-text>
                    </b-card>
                </b-col>
            </b-row>
            <b-row v-else  no-gutters class="p-5 d-flex">
                <b-col xs="auto" sm="auto" md="auto" lg="auto" xl="auto" class="p-2 w-100 m-sm-auto">
                    <h1 class="text-center font-weight-bolder">Thank you please close the window...</h1>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import {API} from "@/API";

import {DxForm, DxSimpleItem, DxLabel, DxGroupItem, DxEmptyItem, DxButtonItem, DxButtonOptions} from 'devextreme-vue/form';
import { DxNumberBox, DxButton as DxNumberBoxButton } from 'devextreme-vue/number-box';

import 'devextreme-vue/text-area';
import {confirm} from "devextreme/ui/dialog";

export default {
    name: "credit-request-view",

    components: {
        DxForm,
        DxSimpleItem,
        DxLabel,
        DxGroupItem,
        DxButtonItem,
        DxButtonOptions,
        DxEmptyItem,
        DxNumberBox,
        DxNumberBoxButton
    },

    data() {
        return {
            title: this.$appInfo.title,
            company: this.$appInfo.company,
            payload: {
                CSRF_Token: "",
                CustomerNo: "",
                Comment: "",
                RequestedLoadCount: 0,
                ApprovedLoadCount: 0,
                Approved: false
            },
            max: 1,
            min: 1,
            step: 1,
            datasource: null,
            processed: false,

            prevButton: {
                icon: 'fas fa-chevron-left',
                stylingMode: 'text',

                onClick: (e) => {
                    if(this.payload.ApprovedLoadCount > 1)
                        this.payload.ApprovedLoadCount--
                },
            },
            nextButton: {
                icon: 'fas fa-chevron-right',
                stylingMode: 'text',

                onClick: (e) => {
                    if(this.payload.ApprovedLoadCount < this.payload.RequestedLoadCount)
                        this.payload.ApprovedLoadCount++
                },
            },
        }
    },

     async created() {
        this.payload.CSRF_Token = this.$route.params.csrf_token
        this.payload.CustomerNo = this.$route.params.customer_no

        if (this.payload.CSRF_Token && this.payload.CustomerNo) {
            this.Fetch()
        } else {
            this.$router.push('/login')
        }
    },

    methods: {
        Fetch() {
            this.$store.dispatch('loading/message', "Retrieving Credit Profile...")

            API.get(`/requests/credit/${this.payload.CustomerNo}/${this.payload.CSRF_Token}`)
                    .then(response => {

                        if(response.data) {
                            this.datasource = response.data

                            this.payload.RequestedLoadCount = this.datasource.RequestedLoadCount
                            this.payload.ApprovedLoadCount = this.datasource.RequestedLoadCount
                            this.max = this.datasource.RequestedLoadCount
                        }
                        else {
                            this.$router.push('/login')
                        }
                    })
                    .catch(error => {
                        this.$toast.open({
                            message: error.response.data.message,
                            type: 'error',
                        });
                    })
        },

        ApproveRequest(e) {
            let result = confirm("Are you sure?", `Confirm Accept`);

            result.then((dialogResult) => {
                if (dialogResult) {
                    this.payload.Approved = true

                    this.$store.dispatch('loading/message', "Sending Request...")

                    API.post(`/requests/credit/${this.payload.CustomerNo}/${this.payload.CSRF_Token}`, this.payload)
                            .then(response => {
                                this.processed = true
                            })
                            .catch(error => {
                                this.$toast.open({
                                    message: error.response.data.message,
                                    type: 'error',
                                });
                            })
                }
            })
        },

        DeclineRequest(e) {
            let result = confirm("Are you sure?", `Confirm Decline`);

            result.then((dialogResult) => {
                if (dialogResult) {
                    this.payload.Approved = false
                    this.payload.ApprovedLoadCount = 0

                    this.$store.dispatch('loading/message', "Sending Request...")

                    API.post(`/requests/credit/${this.payload.CustomerNo}/${this.payload.CSRF_Token}`, this.payload)
                            .then(response => {
                                this.processed = true
                            })
                            .catch(error => {
                                this.$toast.open({
                                    message: error.response.data.message,
                                    type: 'error',
                                });
                            })
                }
            })
        },

        getSizeQualifier(width) {
            if (width < 768) return "xs";
            if (width < 992) return "sm";
            if (width < 1200) return "md";
            return "lg";
        },

        onCommentChanged(e) {
            if(e.value)
                this.payload.Comment = e.value
        },

        onLoadsChanged(e) {
            this.payload.ApprovedLoadCount = e.value
        }
    },

    computed: {

    }
}
</script>

<style scoped>

::v-deep .dx-texteditor.dx-state-readonly {
    border-style: solid !important;
    border-color: rgba(0, 0, 0, 0.35);
    background-color: whitesmoke;
    font-weight: bold;
}

::v-deep .dx-layout-manager .dx-field-item:not(.dx-last-col) {
    padding-right: 0 !important;
}

::v-deep .dx-layout-manager .dx-label-h-align.dx-flex-layout:not(.dx-field-item-label-align) {
    align-items: center;
}

::v-deep label {
    display: inline-block;
    margin-bottom: 0;
}
</style>
