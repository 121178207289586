<template>
        <b-container class="p-4">
            <b-row>
                <b-col>
                    <div class="text-center">
                        <img style="height: 4.25rem; width: 20rem" src="SmartRacks.svg" class="rounded mb-2">
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <div class="header">
                        <div class="text-uppercase text-center pb-2 title text-dark">Register Your Account</div>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <DxForm
                        ref="CustomerFormRef"
                        :width="350"
                        :form-data.sync="customer"
                        :show-colon-after-label="true"
                    >
                        <DxSimpleItem
                                data-field="Email"
                                :editor-options="{ height: 35 }"
                        >
                        </DxSimpleItem>

                        <DxSimpleItem
                                :is-required="true"
                                :editor-options="{ mode: 'password', height: 35 }"
                                help-text="Password must be at least 8 characters"
                                data-field="Password"
                        />

                        <DxSimpleItem
                                :is-required="true"
                                data-field="ConfirmPassword"
                                :editor-options="{ mode: 'password', height: 35 }"
                                editor-type="dxTextBox"
                        >
                            <DxLabel text="Confirm Password"/>
                        </DxSimpleItem>

                        <DxSimpleItem
                                data-field="FirstName"
                                :editor-options="{ height: 35 }"
                        />

                        <DxSimpleItem
                                data-field="LastName"
                                :editor-options="{ height: 35 }"
                        />

                        <DxSimpleItem
                                :editor-options="{ height: 35, maxLength: 11, mask: 'Y (X00)-000-0000', mode: 'tel', maskRules: { 'Y': /[1]/, 'X': /[1-9]/ }, showClearButton: true }"
                                data-field="Phone"
                        />
                        <DxSimpleItem
                            data-field="DevicePlatform"
                            editor-type="dxSelectBox"
                        >
                            <DxLabel text="Platform" />
                            <template #default>
                                <DxSelectBox :data-source="devicePlatforms" value-expr="ID" display-expr="Name" :height="35" :value="devicePlatforms[1].ID" @selection-changed="onDevicePlatformSelectionChanged"/>
                            </template>
                            <DxRequiredRule/>
                        </DxSimpleItem>

                        <DxSimpleItem
                            data-field="DeviceType"
                            editor-type="dxSelectBox"
                        >
                            <DxLabel text="Type" />
                            <template #default>
                                <DxSelectBox :data-source="deviceTypes" value-expr="ID" display-expr="Name" :height="35" :value="deviceTypes[0].ID" @selection-changed="onDeviceTypeSelectionChanged"/>
                            </template>
                            <DxRequiredRule/>
                        </DxSimpleItem>

                        <DxEmptyItem/>

                        <DxButtonItem
                                :button-options="{ height: 35, text: 'Confirm', type: 'success', width: '100%', useSubmitBehavior: false, onClick: onSubmit, elementAttr: { style: 'font-size: 1.5em; width: 100%;'} }"
                                horizontal-alignment="left"
                        />
                    </DxForm>
                </b-col>
            </b-row>
            <b-row>
                <div class="link text-center m-auto pt-4">
                    By clicking on the "Confirm" button, you verify that you have<br>reviewed and agreed to the FM Fuel & Resources<br><a @click.prevent="openPrivacyNotice" href="">Privacy Notice</a>.
                </div>
            </b-row>
        </b-container>
</template>

<script>
/* eslint-disable */
import { DxSelectBox } from 'devextreme-vue/select-box';
import DxForm, {
    DxGroupItem,
    DxSimpleItem,
    DxButtonItem,
    DxLabel,
    DxRequiredRule,
    DxCompareRule,
    DxRangeRule,
    DxStringLengthRule,
    DxPatternRule,
    DxEmailRule,
    DxEmptyItem
} from 'devextreme-vue/form';

import {API} from "@/API";
import stringHelpers from "@/utils/string-helpers";

const DEVICETYPES = [{ID: 'smartphone', Name: 'Smartphone'}, {ID: 'tablet', Name: 'Tablet'}]
const PLATFORMS = [{ID: 'android', Name: 'Android'}, {ID: 'apple', Name: 'Apple'}]

export default {
    components: {
        DxGroupItem,
        DxSimpleItem,
        DxButtonItem,
        DxLabel,
        DxRequiredRule,
        DxCompareRule,
        DxPatternRule,
        DxRangeRule,
        DxEmailRule,
        DxStringLengthRule,
        DxForm,
        DxEmptyItem,
        DxSelectBox
    },

    data() {
        return {
            deviceTypes: DEVICETYPES,
            devicePlatforms: PLATFORMS,
            environment: process.env,
            title: this.$appInfo.title,
            company: this.$appInfo.company,
            token: null,
            registrationInfo: null,
            customer: null
        };
    },

    mounted() {
        this.token = this.$route.params.token

        if(this.token) {
            this.FetchUserInfo();
        }
        else {
            this.$router.push('/login')
        }
    },

    methods: {
        FetchUserInfo() {
            API.get(`/enroll/info/${this.token}`)
                    .then(response => {
                        this.registrationInfo = response.data.data
                        this.customer = { ...this.registrationInfo }

                        this.customer.DeviceType = DEVICETYPES[0].ID
                        this.customer.DevicePlatform = PLATFORMS[1].ID
                    })
                    .catch(error => {
                        this.$toast.open({
                            message: error.response.data.message,
                            type: 'error',
                            position: 'top-right'
                        });
                    })
                    .finally(() => {
                        this.CustomerForm.instance.getEditor("Password").focus();
                    })
        },

        async Validate(customer) {
            return new Promise((resolve, reject) => {
                if (customer.Email !== this.registrationInfo.Email || customer.Phone !== this.registrationInfo.Phone) {
                    this.CheckAvailability(customer).then(result => {
                        if (!stringHelpers.isEmptyOrWhitespace(customer.Email) && !stringHelpers.isEmailValid(customer.Email)) resolve({
                            valid: false,
                            message: "Email must be valid.",
                            field: 'Email'
                        })
                        else if (stringHelpers.isEmptyOrWhitespace(customer.Email) && stringHelpers.isEmptyOrWhitespace(customer.Phone)) resolve({
                            valid: false,
                            message: "Phone is required.",
                            field: 'Email'
                        })
                        else if (!result.Availability.Phone) resolve({
                            valid: false,
                            message: "Phone is already in use.",
                            field: 'Phone'
                        })
                        else if (!result.Availability.Email) resolve({
                            valid: false,
                            message: "Email is already in use.",
                            field: 'Email'
                        })
                        else if (stringHelpers.isEmptyOrWhitespace(customer.Phone)) resolve({
                            valid: false,
                            message: "Valid Phone Number is required.",
                            field: 'Phone'
                        })
                        else if (stringHelpers.isEmptyOrWhitespace(customer.Password) && stringHelpers.isEmptyOrWhitespace(customer.ConfirmPassword)) resolve({
                            valid: false,
                            message: "Password(s) are required.",
                            field: 'ConfirmPassword'
                        })
                        else if (customer.Password.length < 8) resolve({
                            valid: false,
                            message: "Password must be at least 8 characters.",
                            field: 'Password'
                        })
                        else if (customer.Password !== customer.ConfirmPassword) resolve({
                            valid: false,
                            message: "Password(s) must match.",
                            field: 'Password'
                        })
                        else resolve({
                                valid: true,
                                message: null,
                                field: null
                            })
                    }).catch((error) => {
                        reject()
                    })
                } else {
                    if (!stringHelpers.isEmptyOrWhitespace(customer.Email) && !stringHelpers.isEmailValid(customer.Email)) resolve({
                        valid: false,
                        message: "Email must be valid.",
                        field: 'Email'
                    })
                    else if (stringHelpers.isEmptyOrWhitespace(customer.Email) && stringHelpers.isEmptyOrWhitespace(customer.Phone)) resolve({
                        valid: false,
                        message: "Phone is required.",
                        field: 'Phone'
                    })
                    else if (customer.Password && customer.Password.length < 8) resolve({
                        valid: false,
                        message: "Password must be at least 8 characters.",
                        field: 'Password'
                    })
                    else if (stringHelpers.isEmptyOrWhitespace(customer.Password) && stringHelpers.isEmptyOrWhitespace(customer.ConfirmPassword)) resolve({
                        valid: false,
                        message: "Password(s) are required.",
                        field: 'Password'
                    })
                    else if (customer.Password !== customer.ConfirmPassword) resolve({
                        valid: false,
                        message: "Password(s) must match.",
                        field: 'Password'
                    })
                    else resolve({
                            valid: true,
                            message: null,
                            field: null
                        })
                }
            })
        },

        async CheckAvailability(customer) {
            await this.$store.dispatch('loading/message', "Checking Email/Phone Availability...")

            return new Promise((resolve, reject) => {
                API.post(`/enroll/availability/check/${this.token}`, customer)
                        .then(response => {
                            resolve(response.data.data)
                        })
                        .catch(error => {
                            this.$toast.open({
                                message: error.response.data.message,
                                type: 'error',
                                position: 'top-right'
                            });

                            reject()
                        })
            })
        },

        async onSubmit(e) {
            let result = await this.Validate(this.customer)

            if (!result.valid) {
                this.$toast.open({message: result.message, type: 'error', position: 'top-right'})
                this.CustomerForm.getEditor(result.field).focus()
            } else {
                await this.SendEnrollment()
            }
        },

        async SendEnrollment() {
            API.post(`/enroll/${this.token}`, this.customer)
                    .then(response => {
                        this.$toast.open({
                            message: response.data.message,
                            type: 'success',
                            position: 'top-right'
                        });

                        this.$store.dispatch('impersonate/unpersonate').then(() => {
                            //this.$store.dispatch('user/logout').then(() => {

                                localStorage.removeItem('customer')
                                localStorage.removeItem('profile')
                                localStorage.removeItem('user')
                                localStorage.removeItem('token')
                                localStorage.removeItem('roles')
                                localStorage.removeItem('permissions')
                                localStorage.removeItem('customers')

                                this.$gates.setPermissions([])
                                this.$gates.setRoles([])
                                this.$router.push(`/enroll-success`)
                            //})
                        })
                    })
                    .catch(error => {
                        this.$toast.open({
                            message: error.response.data.message,
                            type: 'error',
                            position: 'top-right'
                        });
                    })
        },

        openPrivacyNotice(e) {
            window.open('/legal/privacy-notice.htm', "_blank")
        },

        onDevicePlatformSelectionChanged(e) {
            this.customer.DevicePlatform = e.selectedItem.ID
        },

        onDeviceTypeSelectionChanged(e) {
            this.customer.DeviceType = e.selectedItem.ID
        }
    },

    computed: {
        CustomerForm() {
            return this.$refs.CustomerFormRef.instance
        }
    }
};
</script>

<style lang="scss">
@import "../../themes/generated/variables.base";
@import "../../fm-styles";
.header {
    text-align: center;
    margin-bottom: 20px;

    .icon {
        color: $FuelMastersAccentRed;
        font-size: 10rem;
        margin: 0
    }

    .title {
        color: $FuelMastersBlue;
        font-size: 22px;
        margin: 0;
    }
}

.dx-invalid-message {
    .dx-overlay-content {
        display: none !important;
    }
}
</style>
